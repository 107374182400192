import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Neon } from '../Neon';
import { Tile } from '../Tile';

import {
	Time,
	List,
	Wrapper,
	Heading,
	Subheading,
	ImageWrapper,
} from './Tiles.styled';

export default function Tiles({ data, title, color }) {
	return (
		<Wrapper>
			{title && (
				<Heading>
					<Neon color={color}>{title}</Neon>
				</Heading>
			)}
			{data && (
				<List>
					{data.map(
						item =>
							item.frontmatter.status === 'public' && (
								<li key={item.id}>
									<Link
										to={`/projects/${item.frontmatter.slug}`}
									>
										<Tile>
											<ImageWrapper>
												<GatsbyImage
													image={getImage(
														item.frontmatter.thumb
															.childImageSharp
															.gatsbyImageData
													)}
													alt={item.frontmatter.title}
												/>
											</ImageWrapper>
											<Subheading>
												{item.frontmatter.title}
											</Subheading>
											<Time>{item.frontmatter.date}</Time>
										</Tile>
									</Link>
								</li>
							)
					)}
				</List>
			)}
		</Wrapper>
	);
}
