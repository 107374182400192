import styled from 'styled-components';

export const Wrap = styled.footer`
	padding: var(--containerPadding);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--gap);
	height: var(--footerHeight);
`;
