import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: var(--headerHeight);
	background: var(--headerBackground);
	padding: 0 var(--containerPadding);
	font-family: var(--sans-serif);
	transition: background 0.5s ease;
	z-index: 2;

	${props =>
		props.$isActive &&
		css`
			background: rgba(0, 0, 0, 0.85);
		`}
`;
