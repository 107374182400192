import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	${media.medium`
		position: static;
		transform: none;
	`}
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	width: fit-content;
	max-width: 220px;
`;

export const Text = styled.span`
	display: flex;
	width: 100px;
`;
