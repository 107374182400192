import styled from 'styled-components';
import { media } from '../../utils';

export const HomeContainer = styled.div`
	display: grid;
	width: 100vw;
	height: 100vh;

	.gatsby-image-wrapper {
		position: initial;
		height: inherit;
	}
`;

export const FeaturedImg = styled.div`
	height: inherit;
	grid-row: 1;
	grid-column: 1;
	z-index: -1;
`;

export const Overlay = styled.div`
	--opacity: 70%;
	background: rgb(0 0 0 / var(--opacity));
	height: inherit;
	grid-row: 1;
	grid-column: 1;

	${media.medium`
		--opacity: 80%;
	`}
`;

export const NeonContainer = styled.div`
	text-align: center;
	height: inherit;
	grid-row: 1;
	grid-column: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--gap);
	z-index: 1;
`;
