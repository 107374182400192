export function formatDateIntl(
	date,
	locale,
	options = {},
	includeCommas = false
) {
	const formatter = new Intl.DateTimeFormat(locale, options);

	if (includeCommas) {
		return formatter.format(date);
	} else {
		const parts = formatter.formatToParts(date);
		return parts
			.map(part =>
				part.type === 'literal'
					? part.value.replace(/,/g, '')
					: part.value
			)
			.join('');
	}
}

const date = new Date();
const formattedDate = formatDateIntl(date, 'en-US', {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
});
const formattedDateWithCommas = formatDateIntl(
	date,
	'en-US',
	{
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	},
	true
);

console.log(formattedDate); // Output (e.g.): November 13 2024
console.log(formattedDateWithCommas); // Output (e.g.): November 13, 2024
