import styled from 'styled-components';

export const Wrapper = styled.article`
	.content {
		padding: var(--containerPadding);
		max-width: 45rem;
		margin: auto;
	}

	ul {
		list-style: none;
		padding-left: 0;
	}
`;

export const Body = styled.div`
	p:first-child:first-letter {
		font: normal 5rem var(--serif);
		float: left;
		margin: var(--lineHeight) var(--lineHeight) 0 0;
		line-height: 0.65;
	}
`;

export const Facts = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const Content = styled.div`
	position: relative;
`;
