import { Link } from 'gatsby';
import React from 'react';
import { Neon } from '../Neon';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
	FeaturedImg,
	HomeContainer,
	NeonContainer,
	Overlay,
} from './Home.styled';

export default function Home({ data }) {
	const { title } = data.site.siteMetadata;
	const image = getImage(data.file.childImageSharp.gatsbyImageData);

	return (
		<HomeContainer>
			<FeaturedImg>
				<GatsbyImage image={image} alt={title} />
			</FeaturedImg>
			<Overlay />
			<NeonContainer>
				<Link to="/gigs">
					<Neon as="h1" color="purple">
						{title}
					</Neon>
					<h2>
						<Neon>Enter</Neon>{' '}
						<Neon color="flicker">
							Here <strong>&rarr;</strong>
						</Neon>
					</h2>
				</Link>
			</NeonContainer>
		</HomeContainer>
	);
}
