import { css } from 'styled-components';

const mediaQuery =
	(...queryFeatures) =>
	(...rules) =>
		css`
			@media ${css(...queryFeatures)} {
				${css(...rules)}
			}
		`;

export const breakpoint = {
	small: 680,
	medium: 768,
	large: 1024,
	xlarge: 1300,
	xxlarge: 2400,
};

/**
 * Helper for media queries based on a particular breakpoint.
 */
export const media = {
	small: mediaQuery`(min-width: ${breakpoint.small}px)`,
	medium: mediaQuery`(min-width: ${breakpoint.medium}px)`,
	large: mediaQuery`(min-width: ${breakpoint.large}px)`,
	xlarge: mediaQuery`(min-width: ${breakpoint.xlarge}px)`,
	xxlarge: mediaQuery`(min-width: ${breakpoint.xxlarge}px)`,
};
