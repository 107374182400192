import React from 'react';
import { Wrap } from './Footer.styled';
import { Icon } from '../Icon';

export default function Footer() {
	return (
		<Wrap>
			<a
				href="https://www.instagram.com/guitarlos/"
				target="_blank"
				rel="noopener noreferrer"
				className="instagram"
				title="Instagram"
			>
				<Icon type="instagram" size="2rem" />
			</a>
			<small>&copy; Guitarlos {new Date().getFullYear()}</small>
		</Wrap>
	);
}
