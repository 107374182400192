/**
 * Converts epoch to human-readable date and time.
 * It utilizes the Intl constructor which enables
 * language-sensitive date and time formatting.
 * It is part part of ECMAScript Internationalization API.
 * Supports Unix timestamps in seconds, milliseconds, microseconds and nanoseconds.
 * @param { Number|String } date
 * @param { String } timeZone
 * @param { String } dateStyle
 * @returns { String } The Intl.DateTimeFormat object enables language-sensitive date and time formatting.
 */
export function formatDate(
	date,
	includeCommas = true,
	options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		timeZone: 'America/Chicago',
	},
	locale = 'en-US'
) {
	const newDate = new Date(date.toString());
	if (includeCommas) {
		return new Intl.DateTimeFormat(locale, options).format(newDate);
	} else {
		return new Intl.DateTimeFormat(locale, options)
			.format(newDate)
			.replace(/,/g, '');
	}
}

// For known issues see https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off

// BASIC OPTIONS
// weekday: 'narrow' | 'short' | 'long',
// era: 'narrow' | 'short' | 'long',
// year: 'numeric' | '2-digit',
// month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
// day: 'numeric' | '2-digit',
// hour: 'numeric' | '2-digit',
// minute: 'numeric' | '2-digit',
// second: 'numeric' | '2-digit',
// timeZoneName: 'short' | 'long',

// TIMEZONE
// timeZone: 'America/Chicago',

// DATE AND TIME STYLES
// dateStyle: 'full' | 'long' | 'medium' | 'short'
// timeStyle: 'full' | 'long' | 'medium' | 'short'

// FORCE 12-hour or 24-hour
// hour12: true | false,

// Rarely-used OPTIONS
// hourCycle: 'h11' | 'h12' | 'h23' | 'h24',
// formatMatcher: 'basic' | 'best fit'
