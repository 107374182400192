import styled from 'styled-components';

export const Wrapper = styled.button`
	font-family: var(--headingFont);
	font-size: 2rem;
	position: fixed;
	top: var(--headerHeight);
	right: unset;
	bottom: unset;
	left: var(--gap);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
	}

	&.variant {
		background: none;
		border: none;
	}
`;
