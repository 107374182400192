import React from 'react';
import { Wrapper, Subtitle, Title } from './Error.styled';

export default function Error() {
	return (
		<Wrapper>
			<Title className="neonText">404</Title>
			<Subtitle className="neonText">Page not found.</Subtitle>
		</Wrapper>
	);
}
