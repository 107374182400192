import React from 'react';
import { useSiteMetadata } from '../../hooks';

export default function SEO({ title, image, description, pathname, children }) {
	// https://www.gatsbyjs.com/docs/how-to/adding-common-features/adding-seo-component/
	const {
		title: defaultTitle,
		description: defaultDescription,
		image: defaultImage,
		siteUrl,
	} = useSiteMetadata();

	const seo = {
		title: title ? title + ' | ' + defaultTitle : defaultTitle,
		description: description
			? description + ' | ' + defaultDescription
			: defaultDescription,
		image: `${siteUrl}${image || defaultImage}`,
		url: `${siteUrl}${pathname || ``}`,
	};

	return (
		<>
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			{children}
		</>
	);
}
