import React, { useState } from 'react';
import { Button, Wrapper } from './Accordion.styled';

// Easily Building a Custom Accordion with Gatsby and JavaScript
// https://www.youtube.com/watch?v=O0uwmVBExAk

export default function Accordion({ title, content }) {
	const [accordionState, setAccordionState] = useState(false);

	const toggleAccordion = () => {
		setAccordionState(!accordionState);
	};

	return (
		<Wrapper className={accordionState ? 'open' : 'closed'}>
			<Button
				onClick={toggleAccordion}
				className={accordionState && 'active'}
			>
				{title}
				<span className="chevron" />
			</Button>
			<div className="accordion-panel">
				<div className="accordion-content">{content}</div>
			</div>
		</Wrapper>
	);
}
