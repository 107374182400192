import styled from 'styled-components';
import { media } from '../../utils';

export const MenuStyles = styled.div``;

export const HamburgerWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: var(--gap);
	transform: translateY(-50%);

	${media.medium`
		display: none;
	`}
`;
