import React, { useEffect, useState } from 'react';
import { Logo } from '../Logo';
import { Wrapper } from './Header.styled';
import { Back, Menu } from '../../components';

export default function Header({ location }) {
	const { pathname } = location;
	const [scrollY, setScrollY] = useState(0);
	const isActive = scrollY > 54;

	function handleScroll() {
		// Change Header background color.
		setScrollY(window.scrollY);
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Wrapper $isActive={isActive}>
			<Logo />
			{pathname !== '/' && <Back />}
			<Menu scrollY={scrollY} />
		</Wrapper>
	);
}
