import { isValidDate } from './isValidDate';

/**
 * Helper to check if a date is in the future
 * @param { Date } date
 * @returns { Boolean }
 */
export function isFutureDatePlus(date) {
	const newDate = new Date(date);
	const today = new Date();
	const todayPlus = today.setHours(today.getHours() - 2); // delay time by 2 hours

	if (newDate && isValidDate(newDate)) {
		return newDate > todayPlus;
	}
}
