import React from 'react';

export default function Hamburger(props) {
	const width = (props.width || 36) + 'px',
		height = (props.height || 30) + 'px',
		halfHeight = parseInt(height.replace('px', '')) / 2 + 'px',
		isOpen = props.isOpen || false,
		strokeWidth = props.strokeWidth || 2,
		halfStrokeWidth = '-' + strokeWidth / 2 + 'px',
		animationDuration = props.animationDuration || '0.4';

	const getTransformValue = (isOpen, defaultPos, rotateVal) => {
		return (
			'translate3d(0,' +
			(isOpen ? halfHeight : defaultPos) +
			',0) rotate(' +
			(isOpen ? rotateVal + 'deg' : '0') +
			')'
		);
	};

	const styles = {
		container: {
			width: width,
			height: height,
			position: 'relative',
			transform: 'rotate(' + (props.rotate || 0) + 'deg)',
			cursor: 'pointer',
		},
		lineBase: {
			display: 'block',
			height: strokeWidth + 'px',
			width: '100%',
			background: props.color || '#000',
			transitionTimingFunction: 'ease',
			transitionDuration: animationDuration + 's',
			borderRadius: (props.borderRadius || 0) + 'px',
			transformOrigin: 'center',
			position: 'absolute',
		},
		firstLine: {
			transform: getTransformValue(isOpen, 0, 45),
			marginTop: halfStrokeWidth,
		},
		secondLine: {
			transitionTimingFunction: 'ease-out',
			transitionDuration: animationDuration / 4 + 's',
			opacity: isOpen ? '0' : '1',
			top: halfHeight,
			marginTop: halfStrokeWidth,
		},
		thirdLine: {
			transform: getTransformValue(isOpen, height, -45),
			marginTop: halfStrokeWidth,
		},
	};

	return (
		<div
			style={styles.container}
			className={props.className}
			onClick={props.menuClicked}
			role="presentation"
		>
			<span style={{ ...styles.firstLine, ...styles.lineBase }} />
			<span style={{ ...styles.secondLine, ...styles.lineBase }} />
			<span style={{ ...styles.thirdLine, ...styles.lineBase }} />
		</div>
	);
}
