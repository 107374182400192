import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.article`
	max-width: 65rem;
	margin: auto;

	h1 {
		text-align: center;
	}
`;

export const Content = styled.div`
	display: grid;
	gap: var(--containerPadding);

	${media.small`
		grid-template-columns: repeat(2, 1fr);
	`}

	${media.large`
		align-items: center;
		grid-template-columns: 1fr 2fr;
	`}
`;

export const FeaturedImg = styled.div`
	overflow: hidden;
	height: fit-content;
	background: var(--photoBg);
	padding: 0.75rem;
`;

export const Description = styled.p`
	margin-top: 0;

	${media.small`
		margin-top: calc(0px - var(--lineHeight));
	`}

	&:first-letter {
		font: normal 5rem var(--serif);
		float: left;
		margin: var(--lineHeight) var(--lineHeight) 0 0;
		line-height: 0.65;
	}
`;
