import React, { useLayoutEffect, useState } from 'react';
import Hamburger from './Hamburger';
import { Nav } from '../Nav';
import { HamburgerWrapper, MenuStyles } from './Menu.styled';

export default function Menu() {
	const [isNavOpen, setIsNavOpen] = useState(false);

	useLayoutEffect(() => {
		if (isNavOpen) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = '';
		};
	}, [isNavOpen]);

	return (
		<MenuStyles>
			<Nav
				isNavOpen={isNavOpen}
				onClose={() => {
					setIsNavOpen(false);
				}}
			/>
			<HamburgerWrapper>
				<Hamburger
					color={'var(--color)'}
					isOpen={isNavOpen}
					menuClicked={() => {
						setIsNavOpen(!isNavOpen);
					}}
					width={27}
					height={18}
				/>
			</HamburgerWrapper>
		</MenuStyles>
	);
}
