import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	width: 100%;
	position: relative;
	margin: var(--gap) 0;

	&::before {
		content: '';
		background-color: var(--color);
		position: absolute;
		width: 1px;
		height: 100%;
	}

	.accordion-panel {
		padding: 0 18px;
		background-color: var(--color);
		color: var(--bg);
		overflow: hidden;
		transition: max-height 0.3s ease-in-out;

		p:first-child:first-letter {
			font: inherit;
			float: none;
			margin: 0;
			line-height: 1;
		}
	}

	.accordion-content {
		margin: 0.5rem 0;
	}

	.chevron {
		position: absolute;
		top: 1.5rem;
		right: 1rem;

		&:before,
		&:after {
			content: '';
			position: relative;
			display: block;
			width: 15px;
			height: 2px;
			background: var(--color);
			transition: 0.2s ease-in-out;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			left: 10px;
			top: -2px;
			transform: rotate(-45deg);
		}
	}

	&.closed {
		.accordion-panel {
			max-height: 0;
		}
	}

	&.open {
		.accordion-panel {
			max-height: 500px;
		}

		.chevron::before {
			transform: rotate(-45deg);
		}

		.chevron::after {
			transform: rotate(45deg);
		}
	}
`;

export const Button = styled.button`
	cursor: pointer;
	outline: none;
	padding: 1rem;
	padding-right: 2rem;
	border: none;
	border-radius: 0;
	text-align: left;
	width: 100%;
	transition: 0.4s;
	background-color: var(--bg);
	color: var(--color);

	&.active {
		background-color: var(--cardBg);
		color: var(--color);

		.chevron::before,
		.chevron::after {
			background: var(--color);
		}
	}

	${media.xlarge`
		&.active,
		&:hover {
			background-color: var(--cardBg);
			color: var(--color);
			
			.chevron::before,
			.chevron::after {
				background: var(--color);
			}
		}
	`}
`;
