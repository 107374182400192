import React from 'react';
import { Link } from 'gatsby';
import { Icon } from '../Icon';
import { Container, Text, Wrapper } from './Logo.styled';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export default function Logo() {
	const { title } = useSiteMetadata();
	return (
		<Wrapper>
			<Link to="/">
				<Container>
					<Icon type="guitar" size="36px" />
					<Text>{title}</Text>
				</Container>
			</Link>
		</Wrapper>
	);
}
