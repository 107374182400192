import styled from 'styled-components';

export const Wrapper = styled.main`
	/* https://css-tricks.com/how-to-create-neon-text-with-css/ */

	background-color: #010a01;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.neonText {
		color: #fff;
		text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
			0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
	}

	h1 {
		font-size: 4.2rem;
		animation: flicker 5s infinite alternate;
	}

	/* Flickering animation */
	@keyframes flicker {
		0%,
		18%,
		22%,
		25%,
		53%,
		57%,
		100% {
			text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
				0 0 40px #0fa, 0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa,
				0 0 150px #0fa;
		}

		20%,
		24%,
		55% {
			text-shadow: none;
		}
	}

	/* Additional styling */

	h2 {
		font-size: 1.8rem;
	}

	h1,
	h2 {
		text-transform: uppercase;
		font-weight: 400;
	}
`;

export const Title = styled.h1``;
export const Subtitle = styled.h2``;
export const Paragraph = styled.p``;
